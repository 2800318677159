import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { authProtectedRoutes } from "routes"
import { useSelector } from "react-redux"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }
  const generateMenu = type => {
	const userData = useSelector(state=>state.Login.authUser);
	const currentUserRole = userData?.role?.role_type?.toLowerCase();
	return authProtectedRoutes.map((route,idr)=>{
		if(!route.users?.some(role => role?.toLowerCase() == currentUserRole)) return
		return route.type === type &&
		(<li key={idr} className="nav-item dropdown">
			<Link className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded={dropdownOpen === idr} to={route.path} onClick={(e) => {
            e.preventDefault(); // Prevent default navigation for toggling
            toggleDropdown(idr);
          }}>
				<i className={route.icon?classname(route.icon,'me-2'):"bx bx-home-circle me-2"}></i>
				{props.t(route.label)} {props.menuOpen}
				{route.children?.length?(<div className="arrow-down"></div>):""}
			</Link> 
			{route.children?.length &&(
				<div className={classname("dropdown-menu", { show: dropdownOpen === idr })} >
					{route.children.map((child,idc)=>child.users?.some(role => role?.toLowerCase() == currentUserRole)
          ?(<Link key={idc} to={child.path} className="dropdown-item">{child.label}</Link>)
          :null)}
			  	</div>
			)}
		</li>)
	});
  }
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">
                {generateMenu('menu')}
                {generateMenu('setting')}
                </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
